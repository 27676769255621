<!--
 Created By: Ankit Sharma
 Date : 22-05-2020
 @ info.ankitamc@gmail.com
 Updated : 23-05-2020
-->


<h2 mat-dialog-title>Load Graph</h2>
<mat-dialog-content class="mat-typography">

  <mat-tab-group mat-align-tabs="center">

    <mat-tab label="Select a graph">

      <mat-form-field>
        <input (keyup)="applyFilter($event.target.value);" matInput placeholder="Filter">
      </mat-form-field>

      <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2">

        <ng-container matColumnDef="srno">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 10%;">S No. </mat-header-cell>
          <mat-cell *matCellDef="let data" style="flex: 0 0 10%;"> {{data.id}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="graphName">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 20%;"> Graph Name </mat-header-cell>
          <mat-cell *matCellDef="let data" style="flex: 0 0 20%;"> {{data.file_name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="parentGraph">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 20%;"> Parent Graph </mat-header-cell>
          <mat-cell *matCellDef="let data" style="flex: 0 0 20%;"> {{data.input_graph}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
          <mat-cell *matCellDef="let data"> {{data.description}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" ></mat-header-row>
        <mat-row matRipple style="cursor: pointer;" *matRowDef="let row; columns: displayedColumns;" (click)="rowclick(row)"></mat-row>
      </mat-table>

    </mat-tab>

    <mat-tab label="Configure a graph">

      <mat-form-field style="width: 100%;">
        <mat-label>You configuration goes here</mat-label>
        <textarea matInput style="height: 40vh" #config>{{json_temp}}</textarea>
      </mat-form-field>

      <div align="center">
        <button mat-raised-button color="primary" (click)="submit(config.value);">Submit</button>
      </div>

    </mat-tab>

  </mat-tab-group>

</mat-dialog-content>

<mat-dialog-actions align="end" layout="row" style="position: fixed; bottom: 10%; right: 12%; width: 100%;
">
  <button mat-raised-button (click)="cancel();">Cancel</button>
</mat-dialog-actions>
