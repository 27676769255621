/**
 * Created By: Ankit Sharma
 * Date : 22-05-2020
 * @ info.ankitamc@gmail.com
 * Updated: 23-05-2020
 */

import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource} from '@angular/material/table';
import {  MatSort } from '@angular/material/sort';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  // Variable Declaration
  dataSource: any;

  // Table Config
  displayedColumns: string[] = ['srno', 'graphName', 'parentGraph', 'description'];
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
      @Inject(MAT_DIALOG_DATA) public id: any, // data binding from dialog from cli component
      private appService: AppService,
      public matdialogRef: MatDialogRef<DialogComponent>
    ) { }

  template = '{ "ver": "1.0", "commands": [{ "graph": [{ "operation": "subgraph", "parameters": { "field": ["designation= \'Area Sales Manager \'"] }, "type": "node", "calcvalues": ["none"] }], "inputgraph": "main", "saveas": "test_on_demand_graph", "description": "Desig wise graph for bu heads from formal network" } ] }';
  json_temp = JSON.stringify(JSON.parse(this.template), undefined, 4);
  ngOnInit() {

    // getTableData service call
    this.appService.getTableData(this.id.id).subscribe(response => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.sort = this.sort; // sort is not working
    });

  }

  // filter and serch function
  applyFilter(filter: string) {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  // on dilog cancel
  cancel() {
    localStorage.setItem('graph', null);
    this.matdialogRef.close();
  }

  // when a graph is clicked
  rowclick(data: any) {
    // get graph service call with study id and graph name
    this.appService.getGraph(this.id.id, data.file_name).subscribe(graph => {
      // console.log("id: ",this.id.id);
      console.log(graph);
      localStorage.setItem('graph', JSON.stringify(graph));
      this.matdialogRef.close();
    });
  }

  // on config submit
  submit(config: any) {
    // puch config service call with study id and config data
    this.appService.pushConfig(this.id.id, config).subscribe( response => {
      console.log(response);
      this.matdialogRef.close();
    });
  }

}
